<script>
import Avatar from 'vue-avatar'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'MainNavAvatar',
  components: {
    Avatar
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    userName () {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`
    },
    hideLogoutBtn () {
      // we don't want to show the logout button when In-Office-Play is being used as it is confused with stopping in office play
      return (
        this.$store.state.user.actualAppUserInfo !== null &&
        this.$store.state.user.actualAppUserInfo.appUserId !== this.$store.state.user.userInfo.appUserId &&
        (this.$store.state.user.scopeAppUserInfo ? this.$store.state.user.scopeAppUserInfo.appUserId !== this.$store.state.user.userInfo.appUserId : true)
      )
    }
  },
  methods: {
    async logout () {
      this.$store.dispatch('brochure/setGuestUser', null)
      this.$store.dispatch('user/logout')
      this.$store.dispatch('app/logout')
      await axios.get('/post-logout')
      this.$store.dispatch('RESET_OVB')
        .then(() => {
          this.$router.push({ name: 'PreLogout' })
        })
    }
  }
}
</script>

<template>
  <section
    class="nav-avatar-section"
  >
    <div class="nav-avatar-container">
      <avatar
        :username="userName"
        background-color="#FFC107"
        :size="80"
        :rounded="false"
        :lighten="50"
        class="mb-4 mr-4"
      />
      <div
        class="user-name"
      >
        <div class="user-name">
          {{ userInfo.firstName }}
        </div>
        <div class="user-name">
          {{ userInfo.lastName }}
        </div>
      </div>
    </div>
    <div
      v-if="!hideLogoutBtn"
      class=""
    >
      <button
        class="button is-primary is-small"
        @click="logout"
      >
        <span class="icon is-medium mr-1">
          <i class="mdi mdi-18px mdi-logout" />
        </span>
        <span class="is-family-semibold">Log Out</span>
      </button>
    </div>
  </section>
</template>

<style scoped lang="scss">
.user-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.support-section {
  background-color: $feldgrau;
}
.nav-avatar {
  &-section {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    //align-items: center;
    flex-grow: 1;
  }
  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &-info {
    background-color: red;
    margin-left: 1rem;
  }
}
.user-name {
  color: $page-background;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.user-scope {
  color: $page-background;
  font-size: 1.00rem;
}
.app-tenant-scope {
  color: $page-background;
  font-size: 1.00rem;
}
</style>
